<script context="module" lang="ts">
	export declare namespace DialogModalNamespace {
		export type Props = {
			title?: string;
			icon?: IconNamespace.IconComponent;
			imageIcon?: string;
			showCloseButton?: boolean;
			afterBorderSnippet?: Snippet;
			'data-testid'?: string;
		} & Pick<ModalNamespace.Props, 'children' | 'preventCloseOnOutsideClick' | 'isShowing'>;
	}
</script>

<script lang="ts">
	import Border from '$lib/components/Border.svelte';
	import CrossCircle from '$lib/components/CrossCircle.svelte';
	import Flex from '$lib/components/Flex.svelte';
	import { Icon } from '$lib/components/icon';
	import type { IconNamespace } from '$lib/components/icon/_PrivateIcon.svelte';
	import Modal, { type ModalNamespace } from '$lib/components/Modal/Modal.svelte';
	import Padding from '$lib/components/Padding.svelte';
	import Plane from '$lib/components/Plane.svelte';
	import Text from '$lib/components/Text.svelte';
	import { baseMediaQuery } from '$lib/utilities/mediaQuery/baseMediaQuery';
	import type { Snippet } from 'svelte';

	let {
		isShowing = $bindable(false),
		icon,
		imageIcon,
		showCloseButton,
		children,
		title,
		afterBorderSnippet,
		...rest
	}: DialogModalNamespace.Props = $props();
</script>

<Modal bind:isShowing {...rest}>
	<Border radius={6} size={1} colour="secondary-white">
		<Plane colour="secondary-white" fullWidth>
			<Padding all={$baseMediaQuery === 'xs' ? 3 : 6} fullWidth>
				<Flex direction="column" gap={2} justify="center" align="center">
					{#if title}
						<Text type="h3" align="center">{title}</Text>
					{/if}
					{#if icon}
						<Flex direction="column" justify="center" align="center">
							<Icon {icon} size={10} colour="secondary-purple-contrast" />
						</Flex>
					{/if}
					{#if imageIcon}
						<Flex direction="column" justify="center" align="center">
							<Icon.Image src={imageIcon} size={14} />
						</Flex>
					{/if}
					{@render children()}
				</Flex>
			</Padding>
		</Plane>
	</Border>

	{#if afterBorderSnippet}
		{@render afterBorderSnippet()}
	{/if}

	{#if showCloseButton}
		<CrossCircle onclick={() => (isShowing = false)} />
	{/if}
</Modal>
